.result {
  text-align: center;

  .title {
    font-size: 3rem;
  }

  .description {
    font-size: 1.125rem;
  }

  .title,
  .description,
  .send-again-email {
    color: $white;
    margin-bottom: 30px;
  }

  .back-to-login {
    color: $white;
  }

  .btn {
    border-radius: 0;
    padding: 10px 22px;
    font-family: Santander-Text-Semibold;
  }

  .btn-outline-white {
    border: solid 2px $white;
    color: $white;
    background-color: transparent;
  }
}
