.header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.nav-link,
.nav-link:hover {
  font-family: Santander-MicroText-Regular;
  color: #9e3667 !important;
  display: block;

  svg {
    padding-right: 4px;
  }
}

.nav-link:hover {
  text-decoration: underline;
}
