.card {
  display: flex;
  justify-content: center;
  text-align: center;

  .forgot-password {
    color: $secondary;
    margin-bottom: 50px;
  }

  .icon-show-password,
  .icon-hide-password {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 15px;
    top: 13px;
    cursor: pointer;
    background: url('../../assets/Hide.svg') no-repeat;
  }

  .icon-hide-password {
    background: url('../../assets/Show.svg') no-repeat !important;
  }

  .login-error {
    color: $error-orange;
    margin-bottom: 0;
  }
}

.captcha-container {
  display: flex;
  justify-content: center;
}
