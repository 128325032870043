$primary: #ec0000;
$secondary: #9e3667;
$dark-secondary: #541c37;
$background-secondary: #732645;
$error-orange: #ff4e00;
$light-error-orange: #ff4e001a;
$dark-red: #982e5b;
$dark-red-opaque: rgba(152, 46, 91, 0.5);
$light-red: #9e366726;
$red: #960000;

$white: #ffffff;
$grey: #666666;
$grey-border: #a2a2a2;
$light-grey: #eeeeee;
$stroke-light-grey: #ebebeb;
$table-heading-grey: rgba(111, 119, 121, 0.1);
$black: #3e3e3e;
$black-pure: #000000;
$disabled-grey: #c4c4c4;
