.navigation-footer {
  display: block;
  position: fixed;
  inset: auto 0 0 0;
  background-color: $white;
  padding-bottom: 1.75rem;
  width: 100%;

  .row {
    border-top: 1px solid $stroke-light-grey;
  }

  @media (min-width: 768px) {
    .row-with-previous {
      gap: 1.25rem;
    }
  }
}
