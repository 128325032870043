@font-face {
  font-family: Santander-Headline-Bold-italic;
  src: url(../assets/fonts/SantanderHeadlineW05-BoldIt.woff);
}

@font-face {
  font-family: Santander-Headline-Bold;
  src: url(../assets/fonts/SantanderHeadlineW05-Bold.woff);
}

@font-face {
  font-family: Santander-Headline;
  src: url(../assets/fonts/SantanderHeadlineW05-Rg.woff);
}

@font-face {
  font-family: Santander-Text-Bold;
  src: url(../assets/fonts/SantanderTextW05-Bold.woff);
}

@font-face {
  font-family: Santander-Text-Semibold;
  src: url(../assets/fonts/SantanderTextW05-SemiBold.woff);
}

@font-face {
  font-family: Santander-Text-Regular;
  src: url(../assets/fonts/SantanderTextW05-Regular.woff);
}

@font-face {
  font-family: Santander-MicroText-Regular;
  src: url(../assets/fonts/SantanderMicroTextW05-Rg.woff);
}
