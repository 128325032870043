.fifth-step {
  .row-first {
    margin-top: 1.875rem;
  }

  .form-row {
    margin-top: 3.75rem;
  }

  .form-row-laws {
    margin-top: 1.125rem;
  }

  .form-title {
    margin-bottom: 1.5rem;
  }

  .terminal-trash {
    padding: 2.75rem 0 0 0;
  }

  .add-section {
    margin: 1rem 0 0;
  }
}
