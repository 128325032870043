.row-title-space {
  margin: 2rem 0 1.5rem;
  display: inline;
}

.modal-card.confirm-bank-card {
  padding-bottom: 2.625rem;

  .card-body {
    padding-bottom: 0;
  }
}

.card-red {
  background: $light-red;
  border: 1px dashed $secondary;
  border-radius: 10px;
  padding: 0 1.25rem 0.625rem;
  margin-bottom: 1.875rem;

  .col:first-child {
    text-align: left;
  }

  .col:last-child {
    text-align: right;
    p {
      font-weight: bold;
    }
  }

  p {
    margin: 1.125rem 0;
    color: $black-pure;
    font-size: 0.875rem;
  }

  hr {
    color: $dark-red;
    margin: 0;
  }
}

.branch-button {
  text-align: center;
  margin-bottom: 0.5rem;
  margin-left: 2rem;
  color: $primary;
}

.branch-button:hover {
  color: $red;
}

.div-container {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
