.search-commerce {
  .search-button {
    width: fit-content;
    margin-block: 1rem 1.3125rem;
    padding: 0.625rem 1.375rem;
  }

  .search-description {
    text-align: center;
    margin-top: 2.625rem;
    color: $black;
    font: normal normal bold 1.25rem Santander-Text-Bold;
    letter-spacing: 0;
  }

  .search-commerce-info {
    margin-top: 43px;
  }
}
