.card {
  display: flex;
  justify-content: center;
  text-align: center;

  .back-to-login {
    color: $secondary;
    margin-bottom: 50px;
  }

  .reset-password-error {
    color: $error-orange;
    margin-bottom: 0;
  }
}

.captcha-container {
  display: flex;
  justify-content: center;
}